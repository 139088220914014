import React from "react";
import {
  SubLayout,
  ClinicsParticle,
  CareerParticle,
  ContactParticle,
  MedicalStaff,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const MedicalStaffPage = () => {
  return (
    <SubLayout>
      <Seo
        title="施設関係者の方へ"
        description="医療従事者の方や老人ホーム・介護施設のご担当者様に向けて、医療法人社団慶育会の強みや特徴について知ることができます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,施設関係者の方へ,老人ホーム,介護施設,ケアマネージャー"
      />
      <MedicalStaff />
      <ClinicsParticle />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default MedicalStaffPage;
